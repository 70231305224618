<!--- View de gerenciamento de registros - Responsável pelo gerenciamento de códigos de registro do eyecarebi.

Criado pelos desenvolvedores:
Richard Moraes
Carlos Oliveira
--->

<template>
<div class="nc-container">
  <label for="codes">Lista de códigos</label><br>
  <ul>
      <li style="list-style-type: none;margin-top: 5px" v-for="code of codes" :key="code.key">
        <a v-if="!code.clinic_id" target="_blank" :href="`${appUrl}/cadastre-se/${code.key}`">{{appUrl}}/cadastre-se/{{code.key}}</a>
        <!-- <span v-if="code.clinic_id != null"> - <b>Já utilizada</b></span> -->
      </li>
  </ul>
  <br>
  <button @click="generateCodes()">Gerar novo código</button><br><br>
</div>
</template>

<script>

export default {
name: 'ManageCodes',
  props: {
    clinic: Object,
  },
  mounted () {
    this.getKeys();
  },
  data () {
    return {
        quantity: 0,
        codes: [],
        appUrl: process.env.VUE_APP_URL,
    }
  },
  methods: {
    getKeys() {
      this.api.getKeys()
        .then(res=> {
          this.codes = res.data;
        })
    },
    generateCodes(){
        this.api
        .generateKey()
          .then(res => {
              this.codes.push(res.data)
          })
    },
  }
}
</script>

<style lang="scss">
.nc-container {
  padding: 40px 20px;
}
</style>
